import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/BitpandaLogo.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/default-image/default_asset_image.png");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/default-image/default-asset.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/global-coin.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/global-etc.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/global-etf.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/global-metal.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/global-stock.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/assets/RaiffeisenLogo.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/AmountInput/AmountInputWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/AssetImage/AssetImage.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/ClickAwayListener/ClickAwayListener.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/ClickAwayListener/useClickAwayListener.ts");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/composableModals/ComposableModalPlayGround.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/composableModals/finishedTransactionsStatusModals/TransactionFailureModal.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/composableModals/finishedTransactionsStatusModals/TransactionSuccessModal.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/composableModals/twoFaModals/ResetTwoFaModal/ResetTwoFaModal.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/composableModals/twoFaModals/SetupTwoFaModal/SetupTwoFaModal.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/composableModals/twoFaModals/TriggerResetTwoFaModal/TriggerResetTwoFaModal.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/composableModals/twoFaModals/TriggerSetupTwoFaModal/TriggerSetupTwoFaModal.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/composableModals/UserAccessModal/UserAccessModal.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/CopyToClipboard/CopyToClipboard.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/CopyToClipboardButton/CopyToClipboardButton.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/DatePicker/DatePicker.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/DigitCodeInput/DigitCodeInput.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/DigitCodeInput/DigitCodeInputPlayground/DigitCodeInputPlayground.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/DocumentationExternalLink/DocumentationExternalLink.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/HyperLinkButtonBase/HyperLinkButtonBase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationEvents"] */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/NavigationEvents/navigation-events.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/NoResults/NoResults.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/PortfolioAssets/PortfolioAssets.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/PriceVariation/PriceVariation.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/QRCode/QRCode.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/ReadOnlyTag/ReadOnlyTag.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/SearchBar/SearchBar.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/Select/Select.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/Sheet/Sheet.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/SortButton/SortButton.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/TabBreadcrumbs/TabBreadcrumbs.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/components/TextInput/TextInput.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/layout/InfoLayout/BitpandaBWhite.svg");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo.ts");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/userinfo/UserInfoContext/UserInfoProvider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/userinfo/UserInfoMountCheckActions.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/utils/tests/components.mock.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/common/utils/userAccess/UserAccessWithRoleCheck.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/core-features/src/lib/error/ErrorBoundary/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/accordion/src/lib/Accordion.use-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionContentImage"] */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/accordion/src/lib/AccordionContentImage.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/accordion/src/lib/AccordionItem.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/bar/src/lib/Bar.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/chart/src/lib/Area/Area.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/chart/src/lib/Donut/Donut.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/dialog/src/lib/Dialog.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/list-cells-selection/src/lib/Indicator.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/list-cells-selection/src/lib/Item.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/list-cells-selection/src/lib/Root.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/navigation-bar/src/lib/NavigationBar.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/progress-bar/src/lib/ProgressBar.use-client.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/design-system/component/read-more/src/lib/ReadMore.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useClientTranslation"] */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/utils-lokalise/src/utils/translation.use-client.ts");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/utils-next-infrastructure/src/lib/monitoring/DatadogRumInit.tsx");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/libs/utils-relay/src/lib/handleGqlErrors/handleGqlErrorsWithSnackbar.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Title"] */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/node_modules/@radix-ui/react-dialog/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Root","Trigger","Portal","Content"] */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/node_modules/@radix-ui/react-dropdown-menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/bitpanda/broker/bitpanda-technology-solutions/whitelabel/services/bts-web/node_modules/next/dist/client/link.js");
